const homesolaslang =
{
    "en": {
        "RequestaQuote": "Request a Quote",
        "RequestaQuote_p1": "Get quotes for your shipments",
        "ContactDetails": "Contact Details",
        "Title": "Title",
        "FirstName": "First Name",
        "LastName": "Last Name",
        "Company": "Company",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "Phone Number",
        "Email": "Email",
        "LocationDetails": "Location Details",
        "FromCityPort": "From City/Port",
        "LandTransportationExport": "Land Transportation – Export",
        "ToCityPort": "To City/Port",
        "LandTransportationImport": "Land Transportation Import",
        "CUCCCode": "CUCC Code(Valid Customer Code Provide by GSL)",
        "ShipmentDate": "Shipment Date",
        "CargoDetails": "Cargo Details",
        "ContainerType": "Container Type",
        "ContainerSize": "Container Size",
        "MonthlyQuantity": "Monthly Quantity",
        "CommodityType": "Commodity Type",
        "DescriptionOfGoods": "Description Of Goods",
        "Remark": "Remark",
        "Remark_p1": " Check this box if you would like to receive marketing offers & materials from GSL. We will not share your information with other companies and we will not fill your mailbox with junk.",
        "Quotationtearmscondition": "Quotation tearms & condition",
        "Typethecodeyousee": "Type the code you see",
        "Submit": "Submit",
    },
    "cn": {
        "RequestaQuote": "申請報價",
        "RequestaQuote_p1": "爲您的貨物報價",
        "ContactDetails": "個人資料",
        "Title": "稱謂",
        "FirstName": "名",
        "LastName": "姓",
        "Company": "公司",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "電話",
        "Email": "電郵",
        "LocationDetails": "詳細地區",
        "FromCityPort": "出發城市/港口",
        "LandTransportationExport": "陸路 - 出口",
         "ToCityPort": "到 城市 / 港口",
        "LandTransportationImport": "陸路 - 入口",
        "CUCCCode": "金星航運提供的客戶代碼",
        "ShipmentDate": "運貨日期",
        "CargoDetails": "貨物内容",
        "ContainerType": "貨櫃種類",
        "ContainerSize": "貨櫃尺寸",
        "MonthlyQuantity": "每月用量",
        "CommodityType": "貨物種類",
        "DescriptionOfGoods": "貨物内容",
        "Remark": "備注",
        "Remark_p1": "點選此方格可得到金星航運最新市場訊息, 我們不會透露或與其它第三方分享客戶的資料,也不會大量發送郵件到客戶郵箱",
        "Quotationtearmscondition": "報價條款",
        "Typethecodeyousee": "請輸入驗證碼",
        "Submit": "提交",
   
    },
    "zh": {
        "RequestaQuote": "申请报价",
        "RequestaQuote_p1": "为您的货物报价",
        "ContactDetails": "个人资料",
        "Title": "称谓",
        "FirstName": "名",
        "LastName": "姓",
        "Company": "公司",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "电话",
        "Email": "电邮",
        "LocationDetails": "详细地区",
        "FromCityPort": "出发城市/港口",
        "LandTransportationExport": "陆运 - 出口",
         "ToCityPort": "到 城市 / 港口",
        "LandTransportationImport": "陆运 - 进口",
        "CUCCCode": "金星航运提供的客户代码",
        "ShipmentDate": "运货日期",
        "CargoDetails": "货物内容",
        "ContainerType": "集装箱种类",
        "ContainerSize": "集装箱尺寸",
        "MonthlyQuantity": "每月数量",
        "CommodityType": "货物种类",
        "DescriptionOfGoods": "货物内容",
        "Remark": "备註",
        "Remark_p1": "点选此方框可得到金星航运最新市场讯息,我们不会透露或与其它第三方分享客户资料,也不会大量发送邮件到客户邮箱",
        "Quotationtearmscondition": "报价条款",
        "Typethecodeyousee": "请输入验证码",
        "Submit": "递交",
   
    },
    "ko": {
        "RequestaQuote": "견적 요청",
        "RequestaQuote_p1": "화물에 대한 견적 요청",
        "ContactDetails": "연락처 상세 정보",
        "Title": "제목",
        "FirstName": "이름",
        "LastName": "성",
        "Company": "회사",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "전화 번호",
        "Email": "Email",
        "LocationDetails": "위치(선적항) 상세",
        "FromCityPort": "출발 도시/항구",
        "LandTransportationExport": "내륙 운송 - 수출",
         "ToCityPort": "목적 도시/항구",
        "LandTransportationImport": "내륙 운송 - 수입",
        "CUCCCode": "화주 코드",
        "ShipmentDate": "선적 일자",
        "CargoDetails": "화물 정보",
        "ContainerType": "컨테이너 Type 선택",
        "ContainerSize": "컨테이너 규격",
        "MonthlyQuantity": "월간 수량",
        "CommodityType": "화물 종류",
        "DescriptionOfGoods": "제품 설명",
        "Remark": "비고",
        "Remark_p1": "GSL로부터 마케팅 제안과 자료를 받고 싶다면 이 상자를 선택하십시오. 우리는 당신의 정보를 다른 회사와 공유하지 않을 것이며 당신의 우편함을 쓰레기(junk)로 가득 채우지 않을 것입니다.",
        "Quotationtearmscondition": "견적 조건",
        "Typethecodeyousee": "보이는 코드를 입력하십시오",
        "Submit": "제출",
   
    },
    "vi": {
        "RequestaQuote": "Yêu cầu báo giá",
        "RequestaQuote_p1": "Nhận báo giá cho lô hàng của bạn",
        "ContactDetails": "Thông tin liên hệ",
        "Title": "Danh xưng",
        "FirstName": "Tên ",
        "LastName": "Họ",
        "Company": "Công ty",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "Số điện thoại",
        "Email": "Thư điện tử",
        "LocationDetails": "địa chỉ cụ thể",
        "FromCityPort": "Từ Thành phố/ Cảng",
        "LandTransportationExport": "Vận tải đường bộ - Xuất khẩu",
         "ToCityPort": "Tới thành phố/ Cảng",
        "LandTransportationImport": "Vận tải đường bộ - Nhập khẩu",
        "CUCCCode": "Mã CUCC (Mã khách hàng được cung cấp bởi GSL)",
        "ShipmentDate": "Ngày xuất hàng",
        "CargoDetails": "Thông tin hàng hóa",
        "ContainerType": "Loại container",
        "ContainerSize": "Kích cỡ container",
        "MonthlyQuantity": "Sản lượng hàng tháng",
        "CommodityType": "Loại hàng",
        "DescriptionOfGoods": "Miêu tả hàng hóa",
        "Remark": "Ghi chú",
        "Remark_p1": "Đánh dấu vào ô này nếu bạn muốn nhận tài liệu & ưu đãi tiếp thị từ GSL. Chúng tôi sẽ không chia sẻ thông tin của bạn với các công ty khác và chúng tôi sẽ không gửi thư rác vào hộp thư của bạn.",
        "Quotationtearmscondition": "Điều khoản và điều kiện báo giá",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Submit": "Nhấn để gửi",
   
    },
    "th": {
        "RequestaQuote": "ขอใบเสนอราคา",
        "RequestaQuote_p1": "รับใบเสนอราคาสำหรับการขนส่งของคุณ",
        "ContactDetails": "รายละเอียดผู้ติดต่อ",
        "Title": "คำนำหน้า",
        "FirstName": "ชื่อ",
        "LastName": "นามสกุล",
        "Company": "บริษัท",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "เบอร์โทร",
        "Email": "อีเมล",
        "LocationDetails": "ข้อมูลสถานที่",
        "FromCityPort": "จากเมือง/ท่าเรือ",
        "LandTransportationExport": "การขนส่งทางบก - นำเข้า",
         "ToCityPort": "ไปเมือง/ท่าเรือ",
        "LandTransportationImport": "การขนส่งทางบก - นำเข้า",
        "CUCCCode": "รหัส CUCC (รหัสลูกค้าที่ถูกต้องให้โดย GSL)",
        "ShipmentDate": "วันที่ขนส่ง",
        "CargoDetails": "รายละเอียดสินค้า",
        "ContainerType": "ประเภทตู้สินค้า",
        "ContainerSize": "ขนาดตู้สินค้า",
        "MonthlyQuantity": "ปริมาณส่งออกต่อเดือน",
        "CommodityType": "ประเภทสินค้า",
        "DescriptionOfGoods": "รายละเอียดสินค้า",
        "Remark": "หมายเหตุ",
        "Remark_p1": "เลือกช่องนี้หากคุณต้องการรับข้อเสนอทางการตลาดและสื่อจาก GSL เราจะไม่เปิดเผยข้อมูลของคุณกับบริษัทอื่น ",
        "Quotationtearmscondition": "เงื่อนไขใบเสนอราคา",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "Submit": "ส่ง",
   
    },
    "in": {
        "RequestaQuote": "Permintaan Penawaran",
        "RequestaQuote_p1": "Dapatkan penawaran untuk pengiriman anda",
        "ContactDetails": "Detail Kontak",
        "Title": "Gelar",
        "FirstName": "Nama Depan",
        "LastName": "Nama Belakang",
        "Company": "Perusahaan",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "Nomor Telepon",
        "Email": "Email",
        "LocationDetails": "Detail Lokasi",
        "FromCityPort": "Dari Kota/Pelabuhan",
        "LandTransportationExport": "Transportasi Darat - Ekspor",
        "ToCityPort": "Ke Kota/Pelabuhan",
        "LandTransportationImport": "Transportasi Darat - Impor",
        "CUCCCode": "Kode CUCC (kode pelanggan yang valid dari GSL)",
        "ShipmentDate": "Tanggal Pengiriman",
        "CargoDetails": "Detail Kargo",
        "ContainerType": "Jenis Kontainer",
        "ContainerSize": "Ukuran Kontainer",
        "MonthlyQuantity": "Kuantitas Bulanan",
        "CommodityType": "Jenis Komoditi",
        "DescriptionOfGoods": "Deskripsi barang",
        "Remark": "Keterangan",
        "Remark_p1": "Centang di kotak jika anda ingin menerima penawaran dan materi pemasaran dari GSL. Kami tidak akan membagikan informasi anda dengan perusahaan lain dan kami tidak akan mengisi mailbox anda dengan sampah.",
        "Quotationtearmscondition": "Syarat & ketentuan Penawaran",
        "Typethecodeyousee": "Masukkan kode yang anda lihat",
        "Submit": "Ajukan",
    },
    "ja": {
        "RequestaQuote": "見積もりを依頼",
        "RequestaQuote_p1": "貨物の見積もりを入手します",
        "ContactDetails": "連絡先の詳細",
        "Title": "称号",
        "FirstName": "名",
        "LastName": "姓",
        "Company": "会社",
        "Location": "Country",
        "State": "State",
        "PhoneNumber": "電話番号",
        "Email": "メールアドレス",
        "LocationDetails": "場所",
        "FromCityPort": "出発する市/港",
        "LandTransportationExport": "陸上輸送－輸出",
        "ToCityPort": "到着する市/港",
        "LandTransportationImport": "陸上輸送－輸入",
        "CUCCCode": "CUCCコード（GSLが提供する有効な顧客コード）",
        "ShipmentDate": "出荷日",
        "CargoDetails": "貨物の詳細",
        "ContainerType": "コンテナの種類",
        "ContainerSize": "コンテナサイズ",
        "MonthlyQuantity": "月間数量",
        "CommodityType": "商品の種類",
        "DescriptionOfGoods": "商品の説明",
        "Remark": "備考",
        "Remark_p1": " GSLからのマーケティングオファーや資料の受け取りを希望する場合は、このチェックボックスをオンにします。当社は、お客様の情報を他社と共有することはありません。また、お客様に大量の迷惑メールが届くこともありません。",
        "Quotationtearmscondition": "見積約款",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Submit": "送信",
    },
}
export default homesolaslang;